<template>
  <div class="pa-3" v-resize="onResize">
    <!-- Alert tidak punya akses halaman start -->
    <div v-show="this.valid == false">
      <v-alert text type="error">
        Kamu tidak punya akses Ke halaman "UserMenu"
      </v-alert>
    </div>
    <!-- Alert tidak punya akses halaman end -->

    <!-- Toolbar title menu pengguna mode desktop start  -->
    <v-toolbar
      flat
      height="64"
      class="mb-3"
      color="transparent"
      v-show="mobile == false && this.valid == true"
    >
      <v-toolbar-title class="title">
        <span>Menu Pengguna</span>
      </v-toolbar-title>
      <v-divider inset vertical class="ml-3 mr-3"></v-divider>

      <v-spacer></v-spacer>

      <!-- Btn toggle menu sidebar & dashboard mode desktop start -->
      <div v-show="mobile == false">
        <v-sheet
          outlined
          max-width="470"
          v-show="valid == true"
          class="pa-1 mr-n4 rounded-lg elevation-3"
        >
          <div class="row no-gutters flex-sm-wrap">
            <v-skeleton-loader
              v-for="btnmenu in 2"
              :key="btnmenu"
              width="105"
              height="38"
              type="image"
              class="rounded-lg ma-1"
              v-show="loading == true"
            ></v-skeleton-loader>
          </div>

          <v-btn-toggle
            group
            mandatory
            v-show="loading == false"
          >
            <v-btn height="38" class="rounded-lg text-capitalize" @click="ShowSidebar">
              <v-icon left>mdi-menu</v-icon>
              <span>SideBar</span>
            </v-btn>

            <v-btn height="38" class="rounded-lg text-capitalize" @click="ShowDashboard">
              <v-icon left>mdi-view-dashboard</v-icon>
              <span>Dashboard</span>
            </v-btn>
          </v-btn-toggle>
        </v-sheet>
      </div>
      <!-- Btn toggle menu sidebar & dashboard mode desktop end -->
    </v-toolbar>
    <!-- Toolbar title menu pengguna mode desktop end  -->    

    <div>
      <!-- Btn toggle menu sidebar & dashboard mode mobile start  -->
      <v-sheet
        outlined
        max-width="470"
        v-show="valid == true && mobile == true"
        class="pa-1 mb-3 mx-auto rounded-lg elevation-3"
      >
        <v-btn-toggle
          group
          mandatory
        >
          <v-btn height="38" class="rounded-lg text-capitalize" @click="ShowSidebar">
            <v-icon left>mdi-menu</v-icon>
            <span>SideBar</span>
          </v-btn>

          <v-btn height="38" class="rounded-lg text-capitalize" @click="ShowDashboard">
            <v-icon left>mdi-view-dashboard</v-icon>
            <span>Dashboard</span>
          </v-btn>
        </v-btn-toggle>
      </v-sheet>
      <!-- Btn toggle menu sidebar & dashboard mode mobile end  -->

      <!-- Card menu SideBar start -->
      <v-card
        outlined
        max-width="470"
        class="mx-auto rounded-lg elevation-3"
        v-show="valid == true && MenuSideBar == true"
      >
        <!--  <v-btn text class="text-capitalize rounded-lg" color="red darken-4">Menu SideBar</v-btn>
          <v-btn text class="text-capitalize rounded-lg" color="red darken-4">Menu Dashboard</v-btn> -->
        <v-toolbar flat height="64">         
          <v-skeleton-loader
            width="300"
            height="45"
            type="card-heading"
            class="mt-n3 ml-n4"
            v-show="loading == true"
          ></v-skeleton-loader>

          <v-toolbar-title class="title">
            <span v-show="loading == false && valid == true">
              Pilih Jabatan
            </span>
          </v-toolbar-title>

          <v-spacer></v-spacer>

            <v-btn
              dark
              depressed
              @click="resetMenu"
              color="red darken-4"
              class="mr-n1 rounded-lg text-capitalize"
              v-show="loading == false && ValidDelete == true"
            >
              Reset Menu
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn
              dark
              depressed
              @click="TambahMenu"
              color="red darken-4"
              class="mr-n1 rounded-lg text-capitalize"
              v-show="loading == false && ValidDelete == true"
            >
              Tambah Menu
            </v-btn>
        </v-toolbar>

        <v-divider></v-divider>
        
        <div class="pa-3">
          <div>
            <v-skeleton-loader
              width="445"
              height="42"
              type="image"
              class="rounded-lg"
              v-show="loading == true"
            ></v-skeleton-loader>

            <v-autocomplete
              solo
              flat
              dense
              outlined
              color="red darken-4"
              class="rounded-lg font-weight-bold"
              v-model="JabatanSelect"
              :items="Jabatan"
              item-text="NamaJabatan"
              item-value="KodeJabatan"
              label="Pilih Jabatan--"
              v-show="loading == false && valid == true"
            >
            </v-autocomplete>
          </div>

          <!-- Btn tampilkan menu -->
          <div class="mt-n3">
            <v-skeleton-loader
              width="445"
              height="40"
              type="image"
              class="mt-7 rounded-lg"
              v-show="loading == true"
            ></v-skeleton-loader>

            <v-btn
              dark
              block
              depressed
              height="40"
              color="red darken-4"
              class="text-capitalize rounded-lg"
              @click="tampilkanMenu"
              v-show="loading == false && ValidCreate == true"
            >
              Tampilkan Menu
            </v-btn>
          </div>
        </div>
      </v-card>
      <!-- Card menu SideBar end -->

      <!-- Card menu dashboard start -->
      <v-card
        outlined
        class="mx-auto rounded-lg elevation-3"
        max-width="470"
        v-show="valid == true && MenuDashboard == true"
      >
        <v-toolbar flat height="64">
          <v-skeleton-loader
            width="300"
            height="40"
            class="mt-n3 ml-n4"
            type="card-heading"
            v-show="loading == true"
          ></v-skeleton-loader>

          <v-toolbar-title class="title">
            <span v-show="loading == false && valid == true">
              Cari User
            </span>
          </v-toolbar-title>

          <v-spacer></v-spacer>
        </v-toolbar>

        <v-divider></v-divider>

        <div class="pa-3">
          <div>
            <v-skeleton-loader
            width="445"
            height="40"
            type="image"
            v-show="loading == true"
          ></v-skeleton-loader>
            <v-autocomplete
              solo
              flat
              dense
              outlined
              color="red darken-4"
              class="rounded-lg font-weight-bold"
              v-model="UserSelect"
              :items="UserData"
              :item-text='NamaUser'
              :item-value="ValueUser"
              label="Pilih Karyawan--"
              v-show="loading == false && valid == true"
            >
            </v-autocomplete>
          </div>

          <div class="mt-n3">
            <v-skeleton-loader
              width="445"
              class="mt-7"
              height="40"
              type="image"
              v-show="loading == true"
            ></v-skeleton-loader>

            <!-- Btn tampilkan menu -->
            <v-btn
              dark
              block
              depressed
              height="40"
              color="red darken-4"
              class="text-capitalize rounded-lg"
              @click="tampilkanMenuDashboard"
              v-show="loading == false && ValidCreate == true"
            >
              Tampilkan Menu
            </v-btn>
          </div>
        </div>
      </v-card>
      <!-- Card menu dashboard end -->
    </div>

    <!-- ----------------------------------- -->

    <!-- Result Menu SideBar -->
    <div class="mt-3" v-show="MenuSideBar == true">
      <v-card
        outlined
        class="mx-auto rounded-lg elevation-3"
        max-width="470"
        v-show="valid == null || valid == true"
      >
        <v-toolbar
          flat
          height="64"
        >
          <v-skeleton-loader
            width="150"
            height="30"
            type="image"
            v-show="loading == true"
          ></v-skeleton-loader>

          <v-toolbar-title class="title">
            <span v-show="loading == false && valid == true">
              SideBar Menu
            </span>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn
            text
            @click="Simpan"
            color="red darken-4"
            class="mr-n1 text-capitalize rounded-lg"
            v-show="JabatanSelect && this.ValidUpdate == true && MenuAda == true"
          >
            <v-icon class="mr-1">mdi-check-circle-outline</v-icon>
            Simpan
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <v-list flat dense>
          <v-list-item-group>
            <v-list-item
              v-for="(item, i) in Beranda"
              :key="i"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox color="red lighten-1" v-on:change="toggleBeranda(item)" v-bind:checked="item.enabled" :input-value="active" v-model="item.enabled"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title v-text="item.menu.Nama"></v-list-item-title>
                </v-list-item-content>

                <!-- Btn Delete -->
                <!-- <div class="mx-n2">
                  <v-btn fab text small @click="MenuBeranda(item)">
                    <v-icon color="red lighten-1">mdi-table-edit</v-icon>
                  </v-btn>
                </div> -->
              </template>
            </v-list-item>
          </v-list-item-group>

          <v-list-group
            v-for="item in Master"
            :key="item.menu.Nama"
            color="dark"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-action>
                <v-checkbox color="red lighten-1" v-on:change="toggleGrup(item)" v-bind:checked="item.enabled" v-model="item.enabled"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-text="item.menu.Nama"></v-list-item-title>
              </v-list-item-content>  
            </template>

            <v-list-group
              v-for="(child,i) in item.ListMenu"
              :key="child.menu.Nama"
              no-action
              sub-group
              color="dark"
            >
              <template v-slot:activator>
                <v-list-item class="ml-n3">
                  <v-list-item-action>
                    <v-checkbox color="red lighten-1" v-on:change="toggleChild(child,i)" v-bind:checked="child.enabled" v-model="child.enabled"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content class="mx-3">
                    <v-list-item-title v-text="child.menu.Nama"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item class="mb-n4">
                <v-list-item-action>
                  <v-btn icon fab text small @click="lihatChild(child)">
                    <v-icon color="red lighten-1" v-show="child.Read == true">mdi-checkbox-marked</v-icon>
                    <v-icon v-show="child.Read == false">mdi-checkbox-blank-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Lihat</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="mb-n4">
                <v-list-item-action>
                  <v-btn icon fab text small @click="tmbhChild(child)">
                    <v-icon color="red lighten-1" v-show="child.Create == true">mdi-checkbox-marked</v-icon>
                    <v-icon v-show="child.Create == false">mdi-checkbox-blank-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Tambah</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="mb-n4">
                <v-list-item-action>
                  <v-btn icon fab text small @click="editChild(child)">
                    <v-icon color="red lighten-1" v-show="child.Update == true">mdi-checkbox-marked</v-icon>
                    <v-icon v-show="child.Update == false">mdi-checkbox-blank-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <v-btn icon fab text small @click="deleteChild(child)">
                    <v-icon color="red lighten-1" v-show="child.Delete == true">mdi-checkbox-marked</v-icon>
                    <v-icon v-show="child.Delete == false">mdi-checkbox-blank-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Hapus</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-divider></v-divider>
          </v-list-group>
        </v-list>
      </v-card>
    </div>

    <!-- Result Menu Dashboard -->
    <div class="mt-3" v-show="MenuDashboard == true">
      <v-card
        outlined
        class="mx-auto rounded-lg elevation-3"
        max-width="470"
        v-show="valid == null || valid == true"
      >
        <v-toolbar flat dense height="64">
          <v-skeleton-loader
            width="150"
            height="30"
            type="image"
            v-show="loading == true"
          ></v-skeleton-loader>

          <v-toolbar-title class="title">
            <span v-show="loading == false && valid == true">
              Dashboard Menu
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="red darken-4"
            @click="SimpanMenuDashboard"
            class="mr-n1 text-capitalize rounded-lg"
            v-show="ListMenuDashboard.length > 0 && this.ValidUpdate == true && MenuUserAda == true"
          >
            <v-icon class="mr-1">mdi-check-circle-outline</v-icon>
            Simpan
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-list flat dense>
          <v-list-item-group
            color="dark"
          >
            <v-list-item
              v-for="(item, i) in ListMenuDashboard"
              :key="i"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox color="red lighten-1" v-on:change="toggleDashboard(item)" v-bind:checked="item.enabled" :input-value="active" v-model="item.enabled"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title v-text="item.Nama"></v-list-item-title>
                </v-list-item-content>

                <!-- Btn Delete -->
                <!-- <div class="mx-n2">
                  <v-btn fab text small @click="MenuBeranda(item)">
                    <v-icon color="red lighten-1">mdi-table-edit</v-icon>
                  </v-btn>
                </div> -->
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </div>
  </div>
</template>

<script>
import api from "@/services/http";
  export default {
    data: () => ({
      mobile:null,
      windowSize: {x: 0, y: 0},
      DialogUserMenu: false,
      DialogTambahMenu: false,
      editedIndex: -1,
      MenuSideBar:true,
      MenuDashboard:false,
      DialogMenuBeranda:false,
      token:null,
      ListMenuDashboard:[],
      loading:true,
      Permission:[],
      MenuBerandaList:[],
      ValidCreate:false,
      ValidDelete:false,
      ValidUpdate:false,
      StringMenu:null,
      MenuTemp:[],
      user:[],
      akun:[],
      valid:null,
      MenuAda:false,
      ParentTemp:[],
      ObjectTemp:[],
      ParentSelect:null,
      JabatanSelect:null,
      UserSelect:null,
      MenuUserAda:false,
      resMenu:[],
      defaultItem: {
        KodeMenu: "",
        Nama: "",
        Object: "",
        UserMenu: "",
        Visible: "",
        Route:"",
        Icon: ""
        
      },
      editedItem: {
        KodeMenu: "",
        Nama: "",
        Object: "",
        UserMenu: "",
        Visible: "",
        Route:"",
        Icon: ""
        
      },
      Jabatan:[],
      UserData:[],
      Beranda: [
        // { Nama: 'Beranda'},
      ],

      Master: [
        // {
        //   items: [
        //     { Nama: 'Karyawan' },
        //     { Nama: 'UserMenu' }

        //   ],
        //   Nama: 'Master',
        // },
      ],
    }),

     mounted(){
      this.token = localStorage.getItem('token')
      this.user = JSON.parse(localStorage.getItem('user'))
      if (this.Permission.some(a => a == 'R')) {
      this.valid = true
      this.getJabatan()
      }else{
        this.loading = false
        this.valid = false
      }
      // await Promise.all([this.saya()])
      
      if (this.Permission.some(a => a == 'C')) {
      this.ValidCreate = true
      }
      if (this.Permission.some(a => a == 'U')) {
      this.ValidUpdate = true
      }
      if (this.Permission.some(a => a == 'D')) {
      this.ValidDelete = true
      }
      
    },
    created(){
    //get action for this page
    console.log('param',this.$route.params.Permission)
    let permisi = this.$route.params.Permission
    this.Permission = permisi == null ? [] : permisi.split(',')
    },

    computed: {
      formTitleUserMenu () {
        return this.editedIndex === -1 ? 'Tambah User Menu' : 'Ubah User Menu'
      },
    },

    watch: {
      windowSize(){
        if (this.windowSize.x < 500) {
          this.titleClass = "d-none"
          this.mobile = true
        }else{
          this.titleClass = "mr-4"
          this.mobile = false
        }
      },

      DialogUserMenu(val) {
        val || this.KeluarDialogUserMenu();
        if (this.DialogUserMenu == true) {
          this.ObjectTemp = ['List','Link']
          api.get('menuselected/'+this.JabatanSelect+'?token='+this.token).then(res=>{
            this.MenuTemp = res.data
            var parent = res.data.Menu.filter( function(item){return (item.menu.ParentId == null && item.menu.Route != null);} );
            this.ParentTemp = parent
          })
        }
      },
      JabatanSelect(){
        this.MenuAda = false
        this.Beranda = []
        this.Master = []
        this.resMenu = []
      },
      UserSelect(){
        this.MenuUserAda = false
        this.ListMenuDashboard = []
      },
      ParentSelect(){
        if (this.ParentSelect == null || this.ParentSelect.length == 0) {
          //do nothing
          this.ObjectTemp = [...new Array]
          this.ObjectTemp = ['List','Link']
        }else if(this.ParentSelect.Object == "List") {
          this.ObjectTemp = ['Page']
        }else{
          //do nothing
        }
      }
    },

    DialogUserMenu (val) {
      val || this.KeluarDialogUserMenu()
    },

    methods: {
      TambahMenu(){
        this.DialogTambahMenu = true
      },
      onResize(){
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      },
      NamaUser(x){
        let NamaJabatan = x.karyawan.jabatan != null ? x.karyawan.jabatan.NamaJabatan : "Tanpa Jabatan"
        return x.karyawan.Nama + ' — ' + NamaJabatan
      },
      ValueUser(x){
        return x.KodeKaryawan
      },
      // NamaUser: x => x.NamaJabatan + ' — ' + x.KodeJabatan,
      tampilkanMenu(){
        api.get('/menuselected/'+this.JabatanSelect+'?token='+this.token).then(
				res => {
          console.log(res.data)
          // A comparer used to determine if two entries are equal.
          const isSameUser = (a, b) => a.IdMenu == b.IdMenu;

          const onlyInLeft = (left, right, compareFunction) => 
            left.filter(leftValue =>
              !right.some(rightValue => 
                compareFunction(leftValue, rightValue)));

          const onlyInA = onlyInLeft(res.data.Menu, res.data.UserMenu, isSameUser);
          console.log(onlyInA);
          // const onlyInB = onlyInLeft(res.data.UserMenu, res.data.Menu, isSameUser);
          let result = res.data.UserMenu.concat(onlyInA);
          result.sort()
          console.log('res',result)

          let tes = []
          for (let index = 0; index < result.length; index++) {
            const element = result[index];
            element.enabled = !!parseInt(result[index].Visible);
            element.Permission = (element.Permission) ? element.Permission.replaceAll(',','') : element.Permission
            element.Permission = (element.Permission) ? Array.from(element.Permission) : element.Permission
            element.Create = (element.Permission) ? element.Permission.includes('C') : false
            element.Read = (element.Permission) ? element.Permission.includes('R') : false
            element.Update = (element.Permission) ? element.Permission.includes('U') : false
            element.Delete = (element.Permission) ? element.Permission.includes('D') : false
            tes.push(element)
          }
          console.log('tes',tes)
          // var id = tes.filter( function(item){return (item.Parent == null && item.Object == "List");} );
          var id = tes.filter( function(item){return (item.menu.ParentId == null && item.menu.Route == null);} );
          // console.log('id',id)
          let List = []
          for (let index = 0; index < id.length; index++) {
            const element = id[index];
            // element.items = tes.filter( function(item){return (item.Parent == id[index].KodeMenu);} );
            element.ListMenu = tes.filter( function(item){return (item.menu.ParentId == id[index].IdMenu);} );
            List.push(element)
          }
          // console.log('lis',List)
          // var home = tes.filter( function(item){return (item.Object == "Link");} );
          var home = tes.filter( function(item){return (item.menu.ParentId == null && item.menu.Route != null );} );
          let beranda = []
          for (let index = 0; index < home.length; index++) {
            const element = home[index];
            beranda.push(element)
          }
          // // console.log('akwowk',List)
          // // this.List = List
          this.Beranda = beranda
          this.Master = List
          this.resMenu = result
          this.MenuAda = true
				},
				err => {
					console.log(err);
				}
			)
      },
      tampilkanMenuDashboard(){
       console.log(this.UserSelect)
      this.StringMenu = this.UserData.filter(x=>x.KodeKaryawan == this.UserSelect)
      // console.log(this.StringMenu)
       let data = this.UserData.filter(x=>x.KodeKaryawan == this.UserSelect)
       data = [
         {
          Nama:"Detail Karyawan",
          key:1,
          enabled:(data[0].MenuDashboard) ? data[0].MenuDashboard.includes('1') : false
         },
         {
          Nama:"Detail Kontrak",
          key:2,
          enabled:(data[0].MenuDashboard) ? data[0].MenuDashboard.includes('2') : false
         },
         {
          Nama:"Detail Jabatan",
          key:3,
          enabled:(data[0].MenuDashboard) ? data[0].MenuDashboard.includes('3') : false
         },
         {
          Nama:"Detail Keterlambatan",
          key:4,
          enabled:(data[0].MenuDashboard) ? data[0].MenuDashboard.includes('4') : false
         },
         {
          Nama:"Chart Keterlambatan Harian",
          key:5,
          enabled:(data[0].MenuDashboard) ? data[0].MenuDashboard.includes('5') : false
         },
         {
          Nama:"Detail Cuti",
          key:6,
          enabled:(data[0].MenuDashboard) ? data[0].MenuDashboard.includes('6') : false
         },
         {
          Nama:"Karyawan List Cuti",
          key:7,
          enabled:(data[0].MenuDashboard) ? data[0].MenuDashboard.includes('7') : false
         }
       ]
       console.log('tes',data)
      this.ListMenuDashboard = data
      this.MenuUserAda = true
      },

    //   async saya(){
    //    if (this.$menu == undefined) {
    //      await api.get('/menu?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
    //     res=>{
    //       if (res) {
    //         this.akun = res.data
    //         let route = this.$route.name
    //         let a = this.akun.filter( function(item){return (item.Route == route);} );
    //         if (a.length > 0) {
    //            this.valid = true
    //         }else{
    //           this.valid = false
    //           this.loading = false
    //         }
    //         //do noting
    //       }
    //     }).catch(err=>{
    //       // alert(err)
    //       if (err == "Error: Request failed with status code 401" && this.$route.path != "/login") {
    //         this.logout()
    //       }
    //     })
    //    }else{
    //     this.akun = this.$menu
    //     let route = this.$route.name
    //         let a = this.akun.filter( function(item){return (item.Route == route);} );
    //         if (a.length > 0) {
    //            this.valid = true
    //         }else{
    //           this.valid = false
    //           this.loading = false
    //         }
    //    }     
    // },
    ShowSidebar(){
      this.MenuSideBar = true
      this.MenuDashboard = false
    },  
    ShowDashboard(){
      this.MenuSideBar = false
      this.MenuDashboard = true
    },
      // cekmenu(){
      toggleBeranda(item){
        // console.log(item)
        item.enabled = !!item.enabled
        item.Visible = item.enabled*1
        // api.put("/updatemenu/" +item.id+'?token='+this.token,{
        //     Visible: item.Visible,
        //     data: item,
				// 	})
				// 	.then((res) => {
				// 		if (res) {
				// 			//do nothing
				// 		}
				// 	})
        // console.log(this.Beranda)
      },
      toggleGrup(item){
        // console.log(item)
        item.enabled = !!item.enabled
        item.Visible = item.enabled*1
        let a = []
        for (let index = 0; index < item.ListMenu.length; index++) {
          const element = item.ListMenu[index];
          element.enabled = item.enabled
          element.Visible = item.Visible
          element.Permission = element.Permission == null ? [] : element.Permission
          if (element.enabled == true) {
            let a = element.Permission
            element.Permission = []
            a.push('C','R','U','D')
            element.Permission = a
          }else{
            element.Permission = element.Permission.filter(Permission => Permission !== 'C')
            element.Permission = element.Permission.filter(Permission => Permission !== 'R')
            element.Permission = element.Permission.filter(Permission => Permission !== 'U')
            element.Permission = element.Permission.filter(Permission => Permission !== 'D')
          }
          element.Read = element.enabled
          element.Create = element.enabled
          element.Update = element.enabled
          element.Delete = element.enabled
          a.push(element)
        }
        
        item.items = a
        // this.CekChildEnabled(item)
        // api.put("/updatemenu/" +item.KodeMenu+'?token='+this.token,{
        //     Visible: item.Visible,
				// 	})
				// 	.then((res) => {
				// 		if (!res) {
				// 			//do nothing
				// 		}else{
        //       // this.getdata()
				// 		}
						
				// 	})
      },
      
      toggleChild(child){
        // console.log(child)
        child.enabled = !!child.enabled
        child.Visible = child.enabled*1
        // console.log('wokowk',this.resMenu)
        this.CekChildEnabled(child)
        child.Read = child.enabled
        child.Create = child.enabled
        child.Update = child.enabled
        child.Delete = child.enabled

        // api.put("/updatemenu/" +child.KodeMenu+'?token='+this.token,{
        //     Visible: child.Visible,
				// 	})
				// 	.then((res) => {
				// 		if (!res) {
				// 			//do nothing
				// 		}else{
        //       // this.getdata()
				// 		}
						
				// 	})
      },
      toggleDashboard(x){
        x.enabled = !!x.enabled
        let Menu = this.StringMenu[0].MenuDashboard
        Menu = Menu.replaceAll(',','')
        let data = [...Menu]
        let realdata = data
        if (x.enabled == false) {
        realdata = realdata.filter(e => e != x.key.toString())
        this.StringMenu[0].MenuDashboard = realdata.toString()
        }else{
          realdata.push(x.key)
          this.StringMenu[0].MenuDashboard = realdata.toString()
        }
      },
      hpsBeranda(item){
       var r = confirm("Yakin Hapus Menu "+item.Nama+" ?");
        if (r == true) { 
          api
            .delete("/menu/"+item.KodeMenu+'?token='+this.token)
            .then((res) =>{
              if (res) {
                this.tampilkanMenu()
              }else{
                //do nothing
              }
              
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
      hpsGrup(item){
       var r = confirm("Yakin Hapus Menu "+item.Nama+" ?");
        if (r == true) { 
          api
            .delete("/menu/"+item.KodeMenu+'?token='+this.token)
            .then((res) =>{
              if (res) {
                this.tampilkanMenu()
              }else{
                //do nothing
              }
              
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
      lihatChild(child){
        console.log(child)
        if (child.Read == true) {
          child.Permission = child.Permission.filter(Permission => Permission !== 'R')
        }else{
          let a = child.Permission == null ? [] : child.Permission
          a.push('R')
          child.Permission = a
        }
        
        let dat = child.Permission.toString()
        console.log(dat)
        console.log(this.resMenu.Menu)
        // let final = dat.replace(/,/g , "")
        // child.Permission = (child.Create) ? child.Permission.filter(Permission => Permission !== 'c') : child.Permission.push("c")
        child.Read = !child.Read
        this.CekRowAktif(child)
        // api.put("/updatepermission/" +child.KodeMenu+'?token='+this.token,{
        //     Permission: dat,
				// 	})
				// 	.then((res) => {
				// 		if (!res) {
				// 			//do nothing
				// 		}else{
        //       // this.getdata()
				// 		}
						
				// 	})
      },
      tmbhChild(child){
        if (child.Create == true) {
          child.Permission = child.Permission.filter(Permission => Permission !== 'C')
        }else{
          let a = child.Permission == null ? [] : child.Permission
          a.push('C')
          child.Permission = a
        }
        let dat = child.Permission.toString()
        console.log(dat)
        console.log(child)
        // let final = dat.replace(/,/g , "")
        // child.Permission = (child.Create) ? child.Permission.filter(Permission => Permission !== 'c') : child.Permission.push("c")
        child.Create = !child.Create
        this.CekRowAktif(child)
        // api.put("/updatepermission/" +child.KodeMenu+'?token='+this.token,{
        //     Permission: dat,
				// 	})
				// 	.then((res) => {
				// 		if (!res) {
				// 			//do nothing
				// 		}else{
        //       // this.getdata()
				// 		}
						
				// 	})
      },
      editChild(child){
        if (child.Update == true) {
          child.Permission = child.Permission.filter(Permission => Permission !== 'U')
        }else{
          let a = child.Permission == null ? [] : child.Permission
          a.push('U')
          child.Permission = a
        }
        let dat = child.Permission.toString()
        // let final = dat.replace(/,/g , "")
        child.Update = !child.Update
        this.CekRowAktif(child)
        console.log(dat)
        // api.put("/updatepermission/" +child.KodeMenu+'?token='+this.token,{
        //     Permission: dat,
				// 	})
				// 	.then((res) => {
				// 		if (!res) {
				// 			//do nothing
				// 		}else{
        //       // this.getdata()
				// 		}
						
				// 	})
      },
      deleteChild(child){
        if (child.Delete == true) {
          child.Permission = child.Permission.filter(Permission => Permission !== 'D')
        }else{
          let a = child.Permission == null ? [] : child.Permission
          a.push('D')
          child.Permission = a
        }
        let dat = child.Permission.toString()
        console.log(dat)
        // let final = dat.replace(/,/g , "")
        child.Delete = !child.Delete
        this.CekRowAktif(child)
        // api.put("/updatepermission/" +child.KodeMenu+'?token='+this.token,{
        //     Permission: dat,
				// 	})
				// 	.then((res) => {
				// 		if (!res) {
				// 			//do nothing
				// 		}else{
        //       // this.getdata()
				// 		}
						
				// 	})
      },
      hpsChild(child){
        console.log(child)
       var r = confirm("Yakin Hapus Menu "+child.Nama+" ?");
        if (r == true) { 
          api
            .delete("/menu/"+child.KodeMenu+'?token='+this.token)
            .then((res) =>{
              if (res) {
                this.tampilkanMenu()
              }else{
                //do nothing
              }
              
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
      CekChildEnabled(child){
        child.Permission = child.Permission == null ? [] : child.Permission
        // console.log('cc',child.Permission)
        if (child.enabled == true) {
          let a = child.Permission
          a.push('C','R','U','D')
          child.Permission = a
        }else{
          child.Permission = child.Permission.filter(Permission => Permission !== 'C')
          child.Permission = child.Permission.filter(Permission => Permission !== 'R')
          child.Permission = child.Permission.filter(Permission => Permission !== 'U')
          child.Permission = child.Permission.filter(Permission => Permission !== 'D')
        }
      },
      CekGrupAktif(child){
        console.log('dd',child)
      },
      CekRowAktif(child){
        if (child.Permission.length != 0) {
          child.enabled = true
          child.Visible = 1
        }else{
          child.enabled = false
          child.Visible = 0
        }
      },
      getJabatan(){
        if (this.valid == true) {
        api.get('/list?token='+this.token).then(
				res => {
          // console.log(res.data)
          this.Jabatan = res.data
          this.loading = false
          this.getUser()
				},
				err => {
					console.log(err);
				})
        }
      },
      getUser(){
        api.get('user?token='+this.token).then(res =>{
          console.log(res.data)
          res.data = res.data.filter(x => x.karyawan != null)
          this.UserData = res.data
          console.log(this.UserData)
        })
      },
      MenuBeranda(child){
        console.log(child)
        let Menu = this.$user.MenuDashboard[0]
        this.MenuBerandaList = Menu
        console.log('tes',Menu)
        this.DialogMenuBeranda = true
        console.log(this.DialogMenuBeranda)
      },
      resetMenu(){
        if (this.valid == true) {
          // console.log(this.$user.KODE_JABATAN)
          var r = confirm("Anda akan Reset Menu Semua Karyawan ?");
          if (r == true) {
            api.get('/resetmenu/'+this.$user.KodeJabatan+'?token='+this.token).then(
            res => {
              if (res) {
                // console.log(res)
                //do nothing
              }
            })
          }else{
            //
          }
          
        }
      },
      Simpan(){
        console.log('simpan',this.resMenu)
        let kode = this.resMenu[0].IdJabatan
        let raw = this.resMenu
        let Menu = []
        for (let index = 0; index < raw.length; index++) {
          const element = raw[index];
          element.Permission = element.Permission == null || element.Permission.length == 0 ? null : element.Permission
          Menu.push(element)
        }
         api.put('updateallpermission/'+kode+'?token='+this.token,{
          Menu: Menu
        })
        .then((res)=>{
          if (res) {
            this.Toast('Menu Di Simpan')
          }
        })
        .catch((err)=> {
          console.log(err)
        })
        console.log('Menu',Menu)
      },

      SimpanMenuDashboard(){
        let menu = this.StringMenu[0].MenuDashboard
        let kode = this.StringMenu[0].Kode
        api.put('updateDashboard/'+kode+'?token='+this.token,{
          MenuDashboard:menu
        }).then((res)=>{
          if (res) {
            this.Toast('Menu Di Simpan')
          }
          console.log(res)
        })
      },

      KeluarDialogUserMenu () {
        this.DialogUserMenu = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.ParentSelect = null
          this.editedIndex = -1
        })
      },
      Toast(data){
      this.$swal.fire({
      toast: true,
      icon: 'success',
      title: data,
      animation: false,
      position: 'top-right',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true})
    },

      Reset() {
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        })
      },
    },
  }
</script>